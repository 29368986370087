import * as React from 'react'
import { Trans } from 'react-i18next'
import { useLanguage } from '@hooks/useLanguage'
import { RouterLink } from '@controls/buttons'
import styles from './ConsentLabel.module.css'

export const ConsentLabel: React.FC = () => {
  const { routes } = useLanguage()
  return (
    <Trans
      i18nKey="global.consents.privacy_policy"
      components={{
        linkToPolicy: <RouterLink className={styles.consentLabel} to={routes.PrivacyPolicy} />
      }}
    />
  )
}
